
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import { Options, Vue } from "vue-class-component";
import ModalErrorBalance from "@/app/ui/views/shipment/booking/component/modal-error-balance.vue";
import PPOBPopupModal from "@/app/ui/components/ppob-popup-modal/index.vue";
import { flags } from "@/feature-flags";
import { FlagsPermissionBooking } from "@/feature-flags/flag-booking";
import ModalInstantBoookingConfirmation from "../../../shipment/booking/component/modal-instant-booking-confirmation.vue";
import { InstantBookingController } from "@/app/ui/controllers/InstantBookingController";

@Options({
  components: {
    ModalErrorBalance,
    PPOBPopupModal,
    ModalInstantBoookingConfirmation
  }
})
export default class ShortcutBooking extends Vue {
  tncModal = false;

  async mounted() {
    await AccountController.getValidationAccount();
  }
  async goToCreate() {
    if (this.isBalanceUnderLimitForPos) {
      this.balanceUnderLimit = true;
      return;
    }
    await AccountController.getValidationAccount();
    if (this.validationAccount) {
      await this.$router.push("/shipment/booking/create");
      dataLayer("booking_manual_shortcut_button", {}, [
        "timestamp",
        "userType",
        "userId"
      ]);
    }
  }

  async goToCreateClientBook() {
    await AccountController.getValidationAccount();
    if (this.validationAccount) {
      await this.$router.push("/shipment/booking/create-client");
      dataLayer("booking_client_shortcut_button", {}, [
        "timestamp",
        "userType",
        "userId"
      ]);
    }
  }

  async goToCreateShipmentBook() {
    await AccountController.getValidationAccount();
    if (this.validationAccount) {
      await this.$router.push(`/shipment/booking/create/by-shipment-id`);
      dataLayer("booking_shipment_shortcut_button", {}, [
        "timestamp",
        "userType",
        "userId"
      ]);
    }
  }

  async goToInstantBooking() {
    this.tncModal = false;
    InstantBookingController.setIsInstantBookingTncConfirmed(true);
    await AccountController.getValidationAccount();
    if (this.validationAccount) {
      await this.$router.push(
        "/shipment/booking/create/instant-booking-shipment"
      );
      dataLayer("booking_instant_shortcut_button", {}, [
        "timestamp",
        "userType",
        "userId"
      ]);
    }
  }

  get isCreateManualAble() {
    return FlagsPermissionBooking.permission_booking_create_manual.isEnabled();
  }

  get isCreateForCorporateAble() {
    return (
      FlagsPermissionBooking.permission_booking_create_for_corporate.isEnabled() &&
      !this.dataProfile.isPosChildAccount
    );
  }

  get isAbleToCreateShipmentId() {
    return FlagsPermissionBooking.permission_booking_create_shipment_id.isEnabled();
  }

  get isAbleToCreateInstantBooking() {
    return FlagsPermissionBooking.permission_instant_booking_create.isEnabled();
  }

  get dataProfile() {
    return AccountController.accountData;
  }
  get accountType() {
    return this.dataProfile.account_type;
  }
  get accountTypeDetail() {
    return this.dataProfile.account_type_detail.type;
  }

  // ppob
  get isPPOBVisible() {
    return flags.feature_ppob_enabled.isEnabled();
  }

  //ppob
  ppobDialogModelValue = false;
  async togglePPOBDialog(bool: boolean) {
    await AccountController.getValidationAccount();
    if (this.validationAccount) {
      this.ppobDialogModelValue = bool;
    }
  }

  get validationAccount() {
    return AccountController.validationAccount;
  }

  balanceUnderLimit = false;
  get isBalanceUnderLimitForPos() {
    return (
      BalanceController.balanceData.walletBalance <
        this.dataProfile.limit_rule_min_balance &&
      this.dataProfile.account_type === "partner"
    );
  }

  onCloseTncModal() {
    this.tncModal = false;
  }
}
