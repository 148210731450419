
import { InstantBookingController } from "@/app/ui/controllers/InstantBookingController";
import Checkbox from "primevue/checkbox";
import { Options, prop, Vue } from "vue-class-component";

class Props {
  isOpen = prop<boolean>({
    default: false,
    required: true,
    type: Boolean
  });
  onClose = prop<Function>({
    default: Function,
    type: Function
  });
}

@Options({
  components: {
    Checkbox
  }
})
export default class ModalInstantBoookingConfirmation extends Vue.with(Props) {
  controller = InstantBookingController;
  agreements = [false, false, false];
  isAgree = false;

  get isNotValid() {
    if (this.agreements.includes(false)) {
      this.isAgree = false;
    }
    return this.agreements.includes(false);
  }

  onConfirm() {
    this.controller.saveUserConsent();
    this.$emit("action");
    this.agreements = [false, false, false];
  }
}
